<template>
    <v-container>
        <base-material-card icon="mdi-basket" :title="`Precios ${producto.nombre ? producto.nombre : ''}`"class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="listas" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.precio="{item}">
                            <v-text-field v-model="item.precio" label="Precio" type="number" step="0.01"></v-text-field>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="save" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'Producto'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            listas: [],
            headers: [
                { text: 'Código', value: 'codigo', sortable: false },
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'Precio neto', value: 'precio', sortable: false },
            ],
            errors: {},
            loading: false,
            producto: {}
        }),
        mounted() {
            this.loadListas()
            this.loadProducto()
        },
        methods: {
            loadProducto() {
                this.$http.get(`/productos/${this.$route.params.id}`).then(response => {
                    this.producto = response.data
                })
            },
            loadListas() {
                this.$http.get('/listas/all').then(response => {
                    this.listas = response.data.map(lista => {
                        lista.precio = null
                        return lista
                    })
                    this.loadPrecios()
                })
            },
            loadPrecios() {
                this.$http.get(`/productos/${this.$route.params.id}/precios`).then(response => {
                    this.listas.map(lista => {
                        let precio = response.data.find(item => {
                            return item.lista_id == lista.id
                        })

                        if (precio) {
                            lista.precio = precio.precio
                        }
                        return lista
                    })
                })
            },
            save() {
                this.loading = true
                let producto = {
                    precios: this.listas.filter(lista => {
                        return lista.precio > 0
                    })
                };

                this.$http.post(`/productos/${this.$route.params.id}/precios`, producto).then(response => {
                    this.$router.push({ name: 'Producto' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                    this.errors = error.response.data
                }).finally(() => {
                    this.loading = false
                })
            },
        }
    }
</script>